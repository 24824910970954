import React, { useState } from "react";
import CreateNewAddressModal from "./address-modal/CreateNewAddressModal";
import { Button, Form, Spin } from "antd";
import { createNewCustomerAddress } from "apis/cart-apis/cartApis";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { useSelector } from "react-redux";

const CustomerSection = ({ onShowAllAddresses, getCustomerAddresses }) => {
  const selectedCustomer = useSelector(
    (state) => state.general.selectedCustomer
  );
  const selectedCustomerAddress = useSelector(
    (state) => state.general.selectedCustomerAddress
  );
  const [addAddressForm] = Form.useForm();
  const [isNewAddressModalOpen, setIsNewAddressModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = (values) => {
    const payload = {
      ...values,
      user_id: selectedCustomer?.id,
    };
    onCreateNewAddress(payload);
  };
  const onCreateNewAddress = async (payload) => {
    try {
      setLoading(true);
      const response = await createNewCustomerAddress(payload);
      if (response.status === 200) {
        await getCustomerAddresses(selectedCustomer?.id);
        setLoading(false);
        setIsNewAddressModalOpen(false);
        successMessage({ message: "Address has been created" });
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };
  return (
    <div>
      <div className="customer-section">
        <h3>Customer</h3>
        {Object.keys(selectedCustomer).length > 0 ? (
          <>
            <p>{selectedCustomer?.name}</p>
            <h3>Contact information</h3>
            <p className="custom_title">{selectedCustomer?.email}</p>
            <p className="custom_title">
              {selectedCustomer?.phone_code + "" + selectedCustomer?.phone ||
                "No phone number"}
            </p>
          </>
        ) : (
          <p className="custom_title">No Customer</p>
        )}
      </div>

      <div className="customer-section">
        <div className="customer-section-header">
          <h3>Shipping address</h3>
          {Object.keys(selectedCustomerAddress).length > 0 && (
            <Button
              onClick={() => setIsNewAddressModalOpen(true)}
              // className="custom_buttons"
              type="primary"
              size="small"
            >
              + Add
            </Button>
          )}
        </div>
        {loading ? (
          <div className="spiner">
            <Spin />
          </div>
        ) : (
          <React.Fragment>
            {Object.keys(selectedCustomerAddress).length > 0 ? (
              <>
                <p className="custom_title">
                  {selectedCustomerAddress?.address_line_1}
                </p>
                <p className="custom_title">
                  {selectedCustomerAddress?.address_line_2}
                </p>
                <p className="custom_title">{selectedCustomerAddress?.city}</p>
                <p className="custom_title">{selectedCustomerAddress?.state}</p>
                <p className="custom_title">
                  {selectedCustomerAddress?.country?.name}
                </p>

                <div className="add_new_address">
                  <div onClick={onShowAllAddresses}>
                    <span className="discount">view all</span>
                  </div>
                </div>
              </>
            ) : Object.keys(selectedCustomer).length ? (
              <>
                <div className="add_new_address">
                  <Button
                    onClick={() => setIsNewAddressModalOpen(true)}
                    className="custom_buttons"
                    type="primary"
                  >
                    Add
                  </Button>
                  <Button
                    onClick={onShowAllAddresses}
                    className="custom_buttons"
                  >
                    Show All
                  </Button>
                </div>
              </>
            ) : (
              <p className="custom_title">No Address</p>
            )}
          </React.Fragment>
        )}
      </div>
      <CreateNewAddressModal
        formName={addAddressForm}
        onFinish={onSubmit}
        isOpen={isNewAddressModalOpen}
        onClose={() => setIsNewAddressModalOpen(false)}
        loading={loading}
      />
    </div>
  );
};

export default CustomerSection;
