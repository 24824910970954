/* eslint-disable no-unused-vars */
import { Breadcrumb } from "antd";
import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  Container,
} from "reactstrap";

import NameMap from "routes/BreadcrumbNameMap";

const HeaderNavBar = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState("transparent");
  const sidebarToggle = useRef(null);

  useEffect(() => {
    if (
      window.innerWidth < 993 &&
      props.history.location.pathname !== props.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
      sidebarToggle.current.classList.toggle("toggled");
    }
  }, [props.history, props.location]);

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };

  const getBreadcrumbItems = () => {
    const pathSnippets = props.location.pathname.split("/").filter((i) => i);
    const extraBreadcrumbItems = pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join("/")}`;
      return NameMap[url] ? (
        <Breadcrumb.Item key={url} style={{ fontSize: 17, cursor: "pointer" }}>
          {NameMap[url]}
        </Breadcrumb.Item>
      ) : (
        ""
      );
    });
    return extraBreadcrumbItems;
  };

  const openSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    sidebarToggle.current.classList.toggle("toggled");
  };

  const handleLogout = () => {
    localStorage.clear();
    props.history.push("/");
  };

  return (
    <Navbar
      color={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? "dark"
          : color
      }
      expand="lg"
      className={
        props.location.pathname.indexOf("full-screen-maps") !== -1
          ? ""
          : "" + (color === "transparent" ? "navbar-transparent " : "")
      }
    >
      <Container fluid>
        <div className="navbar-wrapper">
          <div className="navbar-toggle">
            <button
              type="button"
              ref={sidebarToggle}
              className="navbar-toggler navbar-toggler"
              onClick={openSidebar}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Breadcrumb separator={"/"}>{getBreadcrumbItems()}</Breadcrumb>
        </div>
        <NavbarToggler onClick={toggle}>
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
          <span className="navbar-toggler-bar navbar-kebab" />
        </NavbarToggler>
        <Collapse isOpen={isOpen} navbar className="justify-content-end">
          <Nav navbar>
            <div
              className="nav-link btn-rotate"
              style={{
                color: "red",
                backgroundColor: "lightgrey",
                borderRadius: "5px",
                height: "40px",
              }}
            >
              <i className="nc-icon nc-alert-circle-i" />
              <b>Dev ENV</b>
            </div>

            <NavItem>
              <Link
                to="/"
                className="nav-link btn-rotate"
                onClick={handleLogout}
              >
                <i className="nc-icon nc-button-power" />
                <p>Logout</p>
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

export default HeaderNavBar;
