import Dashboard from "views/screens/dashboard/Dashboard.jsx";
import dashbaordIcon from "../assets/img/1.png";
import NewOrder from "views/screens/orders/NewOrder";

const AdminRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: dashbaordIcon,
    component: Dashboard,
    layout: "/admin",
    subPath: false,
    key: "",
  },
  {
    path: "/draft_orders/new",
    name: "New Order",
    icon: dashbaordIcon,
    component: NewOrder,
    layout: "/admin",
    subPath: false,
    key: "new_order",
  },
];

export default AdminRoutes;
