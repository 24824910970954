import { SET_CUSTOMER_ADDRESS } from "redux/actions/app/cart-actions/generalActions";
import { SET_CUSTOMER } from "redux/actions/app/cart-actions/generalActions";
import { COUNTRIES_META_DATA } from "redux/actions/app/cart-actions/generalActions";

const initialState = {
  countriesMetaData: [],
  selectedCustomer: {},
  selectedCustomerAddress: {},
};

export default function generalReducer(state = initialState, action) {
  switch (action.type) {
    case COUNTRIES_META_DATA:
      return {
        ...state,
        countriesMetaData: action.payload,
      };
      break;
    case SET_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.payload,
      };
      break;
    case SET_CUSTOMER_ADDRESS:
      return {
        ...state,
        selectedCustomerAddress: action.payload,
      };
      break;

    default:
      return state;
  }
}
