import client from "apis/config";
import endPoints from "apis/endPoints";
// import { v4 as uuidv4 } from "uuid";

export const listAllCartItems = async () => {
  let params = "";

  const session_id = await getSessionId();
  params = `?session_id=${session_id}`;

  return await client.get(`${endPoints.CART}${params}`);
};
export const onAddItemToCart = async (payload) => {
  payload.session_id = await getSessionId();
  return await client.post(`${endPoints.CART}/item`, payload);
};

export const onRemoveItemFromCart = async (payload) => {
  payload.session_id = await getSessionId();
  return await client.post(`${endPoints.CART}/item`, payload);
};

export const onIncrementItemQuantityInCart = async (payload) => {
  payload.session_id = await getSessionId();

  return await client.post(`${endPoints.CART}/item`, payload);
};

export const onDecrementItemQuantityInCart = async (payload) => {
  payload.session_id = await getSessionId();
  return await client.post(`${endPoints.CART}/item`, payload);
};

export const placeOrder = async (payload) => {
  const sessionId = await getSessionId();
  return await client.post(
    `${endPoints.PLACE_ORDER}?session_id=${sessionId}`,
    payload
  );
};

const getSessionId = async () => {
  let sessionId = localStorage.getItem("session_id");
  // If there's no session_id in the localStorage, generate a new one and save it
  if (sessionId === undefined || sessionId === "" || sessionId === null) {
    sessionId = generateUniqueKey();
    localStorage.setItem("session_id", sessionId);
  }
  return sessionId;
};

const generateUniqueKey = () => {
  const timestamp = new Date().getTime(); // Current timestamp
  const randomString = Math.random().toString(36).substring(7); // Random string

  // Combining timestamp and random string to create a unique key
  const uniqueKey = `${timestamp}_${randomString}`;

  return uniqueKey;
};

export const applyCouponCode = async (code, user_id = "") => {
  return await client.get(
    `${endPoints.COUPON}?code=${code}&platform=CMS&user_id=${user_id}`
  );
};

export const searchCustomers = async (keyword) => {
  return await client.get(`${endPoints.CUSTOMERS}?keyword=${keyword}`);
};
export const searchCustomerAddresses = async (userId) => {
  return await client.get(`${endPoints.ADDRESSES}?user_id=${userId}`);
};
export const createNewCustomerAddress = async (payload) => {
  return await client.post(`${endPoints.NEW_ADDRESS}`, payload);
};
export const createNewCustomer = async (payload) => {
  return await client.post(`${endPoints.NEW_CUSTOMER}`, payload);
};
