import React, { useCallback, useEffect, useState } from "react";
import "./newOrder.css"; // Make sure to create an OrderForm.css file in the same directory
import PaymentDetails from "../../../components/manual-orders/PaymentDetails"; // This will be a separate component
import CustomerSearch from "../../../components/manual-orders/CustomerSearch";
import ProductModal from "../../../components/manual-orders/ProductModal";
import CustomModal from "components/CustomModal/CustomModal";
import { Button, Card } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { listAllCartItems } from "apis/cart-apis/cartApis";
import { _getAllCartItems } from "redux/actions/app/cart-actions/cart";
import { _onCartItemsFaulure } from "redux/actions/app/cart-actions/cart";
import { useDispatch, useSelector } from "react-redux";
import { _onCartItemsSuccess } from "redux/actions/app/cart-actions/cart";
import { getSizeTitle } from "utils/helpers/helpers";
import { _decrementItemQuantity } from "redux/actions/app/cart-actions/cart";
import { _incrementItemQuantity } from "redux/actions/app/cart-actions/cart";
import { _removeItemFromCart } from "redux/actions/app/cart-actions/cart";
import { Spinner } from "reactstrap";

const NewOrder = () => {
  const dispatch = useDispatch();
  const _cartItems = useSelector((state) => state.cart.cartItems);
  const _isLoading = useSelector((state) => state.cart._isLoading);
  const [cartLoadingRowIndex, setCartLoadingRowIndex] = useState("");
  const [searchProductModalOpen, setSearchProductModalOpen] = useState(false);

  const openSearchPorductModel = () => {
    setSearchProductModalOpen(true);
  };
  const closeSearchProductModel = () => {
    setSearchProductModalOpen(false);
  };

  useEffect(() => {
    getCartItemsFromServer();
  }, [searchProductModalOpen]);

  const getCartItemsFromServer = async () => {
    try {
      dispatch(_onCartItemsSuccess());
      const response = await listAllCartItems();
      if (response.status === 200) {
        dispatch(_getAllCartItems(response.data.data));
        dispatch(_onCartItemsFaulure());
      }
    } catch (error) {
      dispatch(_onCartItemsFaulure());
    }
  };

  return (
    <div className="order-page">
      <div className="left-section">
        <Card style={{ borderRadius: 10 }}>
          <div className="search-and-browse">
            <label htmlFor="product-search">Products</label>
            <div className="search-bar">
              <div className="search-input" onClick={openSearchPorductModel}>
                <p>Search products</p>
              </div>
              <Button
                className="browse-button"
                onClick={openSearchPorductModel}
              >
                Browse
              </Button>
            </div>
          </div>

          <div className="product-table">
            {/* table header */}
            <div className="product_table_header">
              <div className="product">
                <p className="custom_title">Product</p>
              </div>
              <div className="quantity">
                <p className="custom_title">Quantity</p>
              </div>
              <div className="price">
                <p className="custom_title">Total</p>
              </div>
              <div className="action">
                <p className="custom_title">Action</p>
              </div>
            </div>
            {/* table row */}
            {_cartItems?.map((item, index) => {
              return (
                <div className="product_table_row" key={index}>
                  <div className="product">
                    <div className="icon">
                      <img src={item?.coverImage} />
                    </div>
                    <div className="product_detail">
                      <p className="custom_title">{item?.title}</p>
                      <span>{getSizeTitle(item?.variantSize)}</span>
                      {item?.onSale && (
                        <p className="custom_price_tag sale_on">
                          {item?.regularPrice} AED
                        </p>
                      )}
                      <p className="custom_price_tag">
                        {item?.onSale ? item?.salePrice : item?.regularPrice}{" "}
                        AED
                      </p>
                    </div>
                  </div>

                  <div className="quantity">
                    {cartLoadingRowIndex === index && _isLoading ? (
                      <Spinner animation="border" size="sm" role="status" />
                    ) : (
                      <div className="_qtyIncrements">
                        <button
                          disabled={item?.quantity <= 1}
                          onClick={() => {
                            setCartLoadingRowIndex(index);
                            dispatch(
                              _decrementItemQuantity(
                                item?.variantId,
                                item?.cart_id
                              )
                            );
                          }}
                        >
                          -
                        </button>
                        <button>{item?.quantity}</button>
                        <button
                          onClick={() => {
                            setCartLoadingRowIndex(index);
                            dispatch(
                              _incrementItemQuantity(
                                item?.variantId,
                                item?.cart_id
                              )
                            );
                          }}
                        >
                          +
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="total">
                    <p className="custom_price_tag">
                      {(item?.onSale ? item?.salePrice : item?.regularPrice) *
                        item?.quantity}{" "}
                      AED{" "}
                    </p>
                  </div>
                  <div className="action">
                    <DeleteOutlined
                      disabled={_isLoading}
                      onClick={() => {
                        dispatch(
                          _removeItemFromCart(item?.variantId, item?.cart_id)
                        );
                      }}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </Card>
        {_cartItems && _cartItems?.length > 0 && <PaymentDetails />}
      </div>

      <div className="right-section">
        <div className="customer-search">
          <CustomerSearch />
        </div>
      </div>

      <CustomModal
        open={searchProductModalOpen}
        handleCancel={closeSearchProductModel}
      >
        <ProductModal handleCancel={closeSearchProductModel} />
      </CustomModal>
    </div>
  );
};

export default NewOrder;
