export const COUNTRIES_META_DATA = "COUNTRIES_META_DATA";
export const SET_CUSTOMER = "SET_CUSTOMER";
export const SET_CUSTOMER_ADDRESS = "SET_CUSTOMER_ADDRESS";

export const _getCountriesMetaData = (data) => {
  return (dispatch) => {
    dispatch({
      type: COUNTRIES_META_DATA,
      payload: data,
    });
  };
};

export const _setCustomer = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMER,
      payload: user,
    });
  };
};

export const _setCustomerAddress = (userAddress) => {
  return (dispatch) => {
    dispatch({
      type: SET_CUSTOMER_ADDRESS,
      payload: userAddress,
    });
  };
};
