const NameMap = {
  "/admin": "",
  "/admin/dashboard": "Dashboard",
  "/admin/dropdown-content": "Dropdowns Configuration",
  "/admin/dropdown-groups": "Dropdown Groups",
  "/admin/events": "Events Content Management",
  "/admin/news": "News Content Management",
  "/admin/vacancies": "Vacancies Content Management",
  "/admin/banners": "Banners Content Management",
};
export default NameMap;
