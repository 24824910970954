import React from "react";

const Dashboard = () => {
  const dashboardStyle = {
    height: "80vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return <div style={dashboardStyle}>Dashboard</div>;
};

export default Dashboard;
