import React from "react";
import "./addressesModal.css"; // Make sure to create a CustomModal.css file for styling
import { Button, Col, Form, Input, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { setRules } from "utils/helpers/helpers";
const { Option } = Select;

const CreateNewAddressModal = ({
  isOpen,
  onClose,
  formName,
  onFinish,
  loading,
}) => {
  const _countryList = useSelector((state) => state.general.countriesMetaData);

  const renderAddNewAddressForm = () => {
    return (
      <div>
        <br />
        <Form layout="vertical" name={formName} onFinish={onFinish}>
          <Row>
            <Col md={24}>
              <Form.Item
                name={"address_line_1"}
                rules={setRules("Address Line 1")}
              >
                <Input type="text" placeholder="Address Line 1" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                name={"address_line_2"}
                rules={setRules("Address Line 2")}
              >
                <Input type="text" placeholder="Address Line 2" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col md={12}>
              <Form.Item name={"city"} rules={setRules("City")}>
                <Input type="text" placeholder="city" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name={"state"} rules={setRules("State")}>
                <Input
                  type="text"
                  placeholder="state"
                  rules={setRules("state")}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item name={"country_id"} rules={setRules("Country")}>
                <Select>
                  {_countryList?.map((country, index) => (
                    <Option value={country.id} key={index}>
                      {country.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button
              className="custom_buttons"
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button htmlType="submit" className="custom_buttons" type="primary">
              {loading ? "Save...." : "Save"}
            </Button>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="addresses-modal-content">
            <h4>Create New Address</h4>

            {renderAddNewAddressForm()}
          </div>
        </div>
      )}
    </>
  );
};

export default CreateNewAddressModal;
