import React, { useCallback, useEffect, useRef, useState } from "react";
import "./ProductModal.css"; // Ensure you have a CSS file by this name
import { Button, Checkbox, Spin } from "antd";
import { searchProducts } from "apis/products-apis/productsApis";
import { useDispatch, useSelector } from "react-redux";
import { _addItemToCart } from "redux/actions/app/cart-actions/cart";
import { getSizeTitle } from "utils/helpers/helpers";
import { _removeItemFromCart } from "redux/actions/app/cart-actions/cart";
import { requestErrorHandel } from "utils/helpers/helpers";
import { Spinner } from "reactstrap";

const ProductModal = ({ handleCancel }) => {
  const _cartItems = useSelector((state) => state.cart.cartItems);
  const _isLoading = useSelector((state) => state.cart._isLoading);

  const dispatch = useDispatch();
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const debounceTimeout = useRef(null);

  const handelSearchProducts = useCallback(async () => {
    try {
      setLoading(true);
      const response = await searchProducts(search);
      if (response.status === 200 && response.data.data) {
        setProducts(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      requestErrorHandel({ error: error });
      setLoading(false);
    }
  }, [search]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    debounceTimeout.current = setTimeout(() => {
      if (search.trim() !== "") {
        handelSearchProducts();
      } else {
        // Call the API when searchQuery is empty (cleared)
        handelSearchProducts();
      }
    }, 1000); // Adjust the delay time as needed

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    }; // Cleanup the timeout
  }, [search, handelSearchProducts]);

  return (
    <div className="product-modal">
      <div className="modal-header">
        <h4>Products</h4>
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search"
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </div>
      <div className="products_list_container">
        {loading ? (
          <div className="spiner">
            <Spin />
          </div>
        ) : (
          <>
            {products.map((product) => {
              return (
                <React.Fragment key={product.id}>
                  <div className="product_list_parent">
                    <div>
                      <Checkbox
                        disabled
                        checked={_cartItems?.some(
                          (item) => item.productId === product.id
                        )}
                      />
                    </div>
                    <div className="product_list_cover">
                      <img src={product.cover_image} alt="product_cover" />
                    </div>
                    <div>
                      <p>{product.title}</p>
                    </div>
                  </div>
                  {product.variants.map((variant, index) => {
                    return (
                      <div className="product_list_child" key={index}>
                        <div>
                          <Checkbox
                            checked={_cartItems?.some(
                              (item) => item.variantId === variant.id
                            )}
                            disabled={variant.quantity === 0}
                            onClick={() => {
                              const _existingItem = _cartItems?.find(
                                (item) => item.variantId === variant.id
                              );
                              console.log(_existingItem);
                              if (!_existingItem) {
                                dispatch(_addItemToCart(product, variant));
                              } else {
                                dispatch(
                                  _removeItemFromCart(
                                    variant?.id,
                                    _existingItem?.id
                                  )
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="product_list_size">
                          <p>{getSizeTitle(variant.size)}</p>
                        </div>
                        <div className="product_list_title">
                          <p>{variant.quantity} Available</p>
                        </div>
                        <div className="product_list_child_info">
                          <div>
                            {product?.on_sale && (
                              <p className="custom_price_tag sale_on">
                                {product?.regular_price} AED
                              </p>
                            )}
                            <p>
                              {product?.on_sale
                                ? product?.sale_price
                                : product?.regular_price}{" "}
                              AED
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </>
        )}
      </div>

      <div className="modal-footer">
        <div className="modal-footer-varaints">
          <span>{_cartItems?.length} variants selected</span>
        </div>
        <div>
          {_isLoading ? (
            <Spinner animation="border" size="sm" role="status" />
          ) : (
            <Button type="primary" onClick={handleCancel}>
              Done
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductModal;
