import * as React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { Redirect } from "react-router";
import _ from "lodash";
import LoginForm from "views/screens/auth/Login";

export const requestErrorHandel = ({ error }) => {
  // toast.error(error.message);
  if (error?.response?.status === 422) {
    const err = error.response.data.errors;
    NotificationManager.error(err[Object.keys(err)[0]]);
  } else if (error?.response?.status === 400) {
    if (error?.response?.data?.message === "Forbidden access") {
      localStorage.clear();
      window.location.href = "/";
    } else {
      NotificationManager.error(
        error?.response?.data?.message ?? error?.response?.data?.error
      );
    }
  } else if (error?.response?.status === 401) {
    localStorage.clear();
    window.location.href = "/";
  } else if (error?.response?.status === undefined) {
    NotificationManager.error("Server down temporarily");
  } else if (!navigator.onLine) {
    NotificationManager.info(
      "No internet connection, Please check your internet"
    );
  } else {
    NotificationManager.error(
      error?.response?.data?.message ?? error?.response?.data?.error
    );
  }
  return null;
};

export const successMessage = ({ message }) => {
  return NotificationManager.success(message);
};

export const showValidationError = (errorMsg) => {
  const debounceAlert = _.debounce(
    () => NotificationManager.error(errorMsg),
    800
  );
  debounceAlert();
};

export const formateDateWithMoment = (date, formate) => {
  return moment(date).format(formate);
};

export const Capitalize = (str) => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const convertToTitleCase = (value) => {
  return value.replace(/_/g, " ").toUpperCase();
};

export const renderLoader = (
  <LoadingOutlined
    style={{ fontSize: 45, textAlign: "center", alignSelf: "center" }}
  />
);

export const loginRedirect = (props) => {
  const token = localStorage.getItem("userToken");
  return token ? (
    <Redirect to={"/admin/dashboard"} />
  ) : (
    <LoginForm {...props} />
  );
};
export const isNonStringNullOrUndefined = (variable) => {
  return (
    typeof variable !== "string" && variable !== null && variable !== undefined
  );
};

export const roundToTwo = (num) => {
  return +(Math.round(num + "e+2") + "e-2");
};
export const getSizeTitle = (size) => {
  switch (size) {
    case "S":
      return "Small";
    case "M":
      return "Medium";
    case "L":
      return "Large";
    default:
      return "N/A";
  }
};

export const toNumber = (value) => {
  if (value === null) return 0;
  const number = Number(value);
  return isNaN(number) ? 0 : number;
};

export const setRules = (title) => {
  return [
    {
      required: true,
      message: `${title} is required`,
    },
  ];
};

export const formatDecimal = (number) => {
  const formattedNumber = number.toFixed(2);
  if (parseFloat(formattedNumber) === Math.floor(number)) {
    return Math.floor(number).toString();
  }
  return formattedNumber;
};

export const cartItemsSummary = (
  sameDayShipping,
  cashPayment,
  free,
  promoCodeDetails,
  cartItems,
  selectedCountry
) => {
  const rateMultiplier = selectedCountry?.exchange_rate?.rate ?? 1;
  let subTotal = 0;
  let saleItemsTotal = 0;
  let noOfItems = 0;
  cartItems.forEach((item) => {
    if (item.onSale) {
      saleItemsTotal += item.quantity * item.salePrice;
    }
    subTotal +=
      item.quantity * (item.onSale ? item.salePrice : item.regularPrice);
    noOfItems = noOfItems + item.quantity;
  });
  let codFee = 0;
  if (cashPayment) {
    codFee = selectedCountry?.delivery_taxes?.cod_fee; // Add 10 for cash payment
  }

  // Calculate the discount amount based on the discount type
  let discountAmount = 0;
  if (promoCodeDetails.type === "Percentage") {
    discountAmount =
      ((subTotal - saleItemsTotal) * promoCodeDetails.value) / 100;
  } else if (promoCodeDetails.type === "Fixed") {
    discountAmount = promoCodeDetails.value;
  }

  // Calculate delivery charges based on country and shipping type
  let standardDeliveryCharges = 0; // Initial delivery charges
  let sameDayDeliveryCharges = 0; // Initial delivery charges

  let pickAndPackFee = selectedCountry?.delivery_taxes?.pick_n_pack_fee ?? 0; // minimum pick and pack fees
  if (subTotal < selectedCountry?.delivery_taxes?.free_shipping_threshold) {
    sameDayDeliveryCharges +=
      selectedCountry?.delivery_taxes?.same_day_delivery_fee; // Add 30 for same day shipping
    standardDeliveryCharges +=
      selectedCountry?.delivery_taxes?.standard_delivery_fee;

    const totalTaxPercentage =
      selectedCountry?.delivery_taxes?.government_export_tax_percentage +
      selectedCountry?.delivery_taxes?.fuel_surcharge_percentage;
    standardDeliveryCharges +=
      standardDeliveryCharges * (totalTaxPercentage / 100);
    sameDayDeliveryCharges +=
      sameDayDeliveryCharges * (totalTaxPercentage / 100);

    if (noOfItems > 4) {
      // if increases than 5 items, for each item keep on adding 1 aed
      pickAndPackFee = pickAndPackFee + (noOfItems - 4);
    }
    standardDeliveryCharges += pickAndPackFee; // depoter pick and pack
    sameDayDeliveryCharges += pickAndPackFee; // depoter pick and pack
  }
  // Calculate the total amount with discounts, delivery charges, and tax
  if (free) {
    standardDeliveryCharges = 0;
    sameDayDeliveryCharges = 0;
    codFee = 0;
  }
  let totalAmount =
    subTotal + standardDeliveryCharges + codFee - discountAmount;
  if (sameDayShipping) {
    totalAmount = subTotal + sameDayDeliveryCharges + codFee - discountAmount;
  }

  const data = {
    subTotal: formatDecimal(subTotal * rateMultiplier),
    totalAmount: formatDecimal(totalAmount * rateMultiplier),
    discountAmount: formatDecimal(discountAmount * rateMultiplier),
    standardDeliveryCharges: formatDecimal(
      standardDeliveryCharges * rateMultiplier
    ),
    sameDayDeliveryCharges: formatDecimal(
      sameDayDeliveryCharges * rateMultiplier
    ),
    codFee: formatDecimal(codFee * rateMultiplier),
  };
  return data;
};

//test
