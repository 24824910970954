// Inside your OrderPage component or wherever it fits in your code structure
import { Button, Checkbox, Row } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import PromoCode from "./promo-code/PromoCode";
import { NotificationManager } from "react-notifications";
import { placeOrder } from "apis/cart-apis/cartApis";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { cartItemsSummary } from "utils/helpers/helpers";
import { _setCustomer } from "redux/actions/app/cart-actions/generalActions";
import { _setCustomerAddress } from "redux/actions/app/cart-actions/generalActions";
import { _clearCartItems } from "redux/actions/app/cart-actions/cart";

const PaymentDetails = () => {
  const dispatch = useDispatch();
  const _cartItems = useSelector((state) => state.cart.cartItems);
  const _isLoading = useSelector((state) => state.cart._isLoading);
  const selectedCustomerAddress = useSelector(
    (state) => state.general.selectedCustomerAddress
  );
  const selectedCustomer = useSelector(
    (state) => state.general.selectedCustomer
  );
  const [loading, setLoading] = useState(false);

  const _countriesMetaData = useSelector(
    (state) => state.general.countriesMetaData
  );

  const [shippingMethod, setShippingMethod] = useState({
    standardShipping: true,
    sameDayShipping: false,
  });
  const [paymentMethods, setPaymentMethods] = useState({
    free: false,
    online: false,
    cod: true,
  });

  const [isOpen, setIsOpen] = useState(false);
  const [promoCodeDetails, setPromoCodeDetails] = useState(0);
  const [promoCode, setPromCode] = useState("");

  const _userCountry = _countriesMetaData?.find(
    (item) => item.id === selectedCustomerAddress?.country?.id
  );

  const {
    subTotal,
    totalAmount,
    discountAmount,
    standardDeliveryCharges,
    sameDayDeliveryCharges,
    codFee,
  } = cartItemsSummary(
    shippingMethod.sameDayShipping,
    paymentMethods.cod,
    paymentMethods.free,
    promoCodeDetails,
    _cartItems,
    _userCountry
  );

  const handlePromoCodeAndDiscount = (proCode, data) => {
    setPromoCodeDetails(data);
    setPromCode(proCode);
  };

  const handleSubmitOrder = async () => {
    let sessionId = localStorage.getItem("session_id");
    if (Object.keys(selectedCustomer).length === 0) {
      NotificationManager.warning("Select Customer");
      return;
    }
    if (Object.keys(selectedCustomerAddress).length === 0) {
      NotificationManager.warning("Select Customer Address");
      return;
    }

    try {
      setLoading(true);
      const payload = {
        delivery_type: shippingMethod.standardShipping
          ? "STANDARD SHIPPING"
          : "SAME DAY", //standard_delivery, same_day_delivery
        payment_type: paymentMethods.free
          ? "FREE"
          : paymentMethods.online
          ? "ONLINE"
          : "COD", //tabby, tamara, cod, card
        address_id: selectedCustomerAddress?.id, // not null, belongs to this user
        promo_code: promoCode, // validate
        session_id: sessionId, //belongs to this user / validate not null, items length greater than 0, skus quaanityt etc availability
        user_id: selectedCustomer?.id,
        send_to_depoter: true,
        platform: "CMS",
      };
      // console.log("payload", payload);
      // return;

      const resposne = await placeOrder(payload);
      if (resposne.status === 200) {
        successMessage({ message: "Your order has been placed" });
        dispatch(_setCustomer({}));
        dispatch(_setCustomerAddress({}));
        dispatch(_clearCartItems());
        setPromCode("");
        setPromoCodeDetails(0);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  const renderConditionalText = () => {
    if (_cartItems && _cartItems?.length > 0 && discountAmount !== "0") {
      return "Remove";
    } else {
      return "Add";
    }
  };

  const handleStandardShippingChange = () => {
    setShippingMethod({
      standardShipping: true,
      sameDayShipping: false,
    });
  };

  const handleSameDayShippingChange = () => {
    setShippingMethod({
      standardShipping: false,
      sameDayShipping: true,
    });
  };
  const handleFreePaymentMethodChange = () => {
    setPaymentMethods({
      free: true,
      online: false,
      cod: false,
    });
  };
  const handleOnlinePaymentMethodChange = () => {
    setPaymentMethods({
      online: true,
      cod: false,
      free: false,
    });
  };

  const handleCashOnDeliveryMethodChange = () => {
    setPaymentMethods({
      online: false,
      cod: true,
      free: false,
    });
  };

  const showPaymentOptionSection =
    Object.keys(selectedCustomer).length > 0 &&
    Object.keys(selectedCustomerAddress).length > 0;

  return (
    <React.Fragment>
      <div className="payment-section">
        <h6>Shipping Method</h6>
        <div className="payment-row">
          <span>
            <div className="payment-terms">
              <Checkbox
                checked={shippingMethod.standardShipping}
                onChange={handleStandardShippingChange}
              >
                Standard Shipping
              </Checkbox>
              {_userCountry &&
                _userCountry?.delivery_taxes?.same_day_delivery_fee !== 0 && (
                  <Checkbox
                    checked={shippingMethod.sameDayShipping}
                    onChange={handleSameDayShippingChange}
                  >
                    Same Day Shipping
                  </Checkbox>
                )}
            </div>
          </span>
        </div>
        <br />
        <h6>Payment Method</h6>
        <div className="payment-row">
          <span>
            <div className="payment-terms">
              {_userCountry && _userCountry?.delivery_taxes?.cod_fee !== 0 && (
                <Checkbox
                  checked={paymentMethods.cod}
                  onChange={handleCashOnDeliveryMethodChange}
                >
                  Cash on Delivery
                </Checkbox>
              )}
              <Checkbox
                checked={paymentMethods.free}
                onChange={handleFreePaymentMethodChange}
              >
                Free
              </Checkbox>
              <Checkbox
                checked={paymentMethods.online}
                onChange={handleOnlinePaymentMethodChange}
              >
                Card Payment
              </Checkbox>
            </div>
          </span>
        </div>
        <br />
        <div>
          <Checkbox defaultChecked disabled>
            Send To Depoter
          </Checkbox>
        </div>
      </div>

      {showPaymentOptionSection && (
        <div className="payment-section">
          <h4>Payment</h4>
          <div className="payment-details">
            <div className="payment-row">
              <span className="custom_title">Subtotal</span>
              <span className="custom_title">
                {!paymentMethods.free &&
                _cartItems &&
                _cartItems?.length > 0 &&
                subTotal !== 0
                  ? subTotal + " " + _userCountry?.currency
                  : "--"}{" "}
              </span>
            </div>
            {!paymentMethods.free && (
              <div className="payment-row">
                <span
                  className="discount"
                  onClick={() => {
                    if (
                      _cartItems &&
                      _cartItems?.length > 0 &&
                      discountAmount !== "0"
                    ) {
                      setPromoCodeDetails(0);
                      setPromCode("");
                    } else {
                      setIsOpen(true);
                    }
                  }}
                >
                  {renderConditionalText()} discount
                </span>
                <span>
                  {" "}
                  {!paymentMethods.free &&
                  _cartItems &&
                  _cartItems?.length > 0 &&
                  discountAmount !== "0"
                    ? discountAmount + " " + _userCountry?.currency
                    : "--"}{" "}
                </span>
              </div>
            )}
            <div className="payment-row">
              <span>Delivery Charges</span>
              {paymentMethods.free ? (
                "--"
              ) : (
                <span>
                  {shippingMethod.standardShipping
                    ? standardDeliveryCharges
                    : sameDayDeliveryCharges}{" "}
                  {_userCountry?.currency}
                </span>
              )}
            </div>
            <div className="payment-row">
              <span>
                {paymentMethods.free
                  ? "Free"
                  : paymentMethods.online
                  ? "Card Payment"
                  : "Cash on Delivery"}
              </span>
              <span>
                {!paymentMethods.free && codFee !== "0"
                  ? codFee + " " + _userCountry?.currency
                  : "--"}
              </span>
            </div>

            <div className="payment-row total">
              <p className="custom_subtotal">Total</p>
              {_isLoading ? (
                <Spinner animation="border" size="sm" role="status" />
              ) : (
                <p className="custom_subtotal">
                  {" "}
                  {paymentMethods.free
                    ? "0"
                    : _cartItems && _cartItems?.length > 0 && totalAmount !== 0
                    ? totalAmount + " " + _userCountry?.currency
                    : "--"}{" "}
                </p>
              )}
            </div>
            <br />
            <Row justify="end">
              <Button
                type="primary"
                className="custom_buttons"
                disabled={_cartItems?.length === 0}
                onClick={handleSubmitOrder}
              >
                {loading ? (
                  <Spinner animation="border" size="sm" role="status" />
                ) : (
                  "Place Order"
                )}
              </Button>
            </Row>
          </div>
          {Object.keys(selectedCustomer).length > 0 ? (
            <PromoCode
              isOpen={isOpen}
              onClose={() => setIsOpen(false)}
              onApplyPromoCode={handlePromoCodeAndDiscount}
            />
          ) : null}
        </div>
      )}
    </React.Fragment>
  );
};
export default PaymentDetails;
