import React, { useState } from "react";
import Login from "../../../components/Forms/LoginForm.jsx";
import { NotificationContainer } from "react-notifications";
import { login } from "apis/login-apis/login-user-api.js";
import { requestErrorHandel } from "utils/helpers/helpers.js";

const LoginForm = (props) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      const params = {
        identifier: formData.email,
        password: formData.password,
      };
      setLoading(true);

      const response = await login(params);
      if (response.status === 200 && response.data.data) {
        const userData = response.data.data;

        localStorage.setItem("userToken", JSON.stringify(userData?.jwt));

        props.history.push("/admin/dashboard");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
      console.log("error", error);
    }
  };

  return (
    <>
      <Login
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        isLoading={loading}
      />
      <NotificationContainer />
    </>
  );
};

export default LoginForm;
