import React, { useState } from "react";
import "./promoCode.css"; // Make sure to create a CustomModal.css file for styling
import { Button, Row } from "antd";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";
import { applyCouponCode } from "apis/cart-apis/cartApis";
import { requestErrorHandel } from "utils/helpers/helpers";

const PromoCode = ({ isOpen, onClose, onApplyPromoCode }) => {
  const _cartItems = useSelector((state) => state.cart.cartItems);

  const [promoCode, setPromoCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPromoCode, setIsPromoCode] = useState(false);

  const selectedCustomer = useSelector(
    (state) => state.general.selectedCustomer
  );

  const hasNoSaleItems = _cartItems?.some((item) => !item.onSale) ?? false;

  const findSubTotalAmount = () => {
    let subTotal = 0;

    // Calculate subtotal
    _cartItems.forEach((item) => {
      subTotal +=
        item.quantity * (item.onSale ? item.salePrice : item.regularPrice);
    });
    return subTotal.toFixed(2);
  };

  const handleApply = async () => {
    try {
      if (!hasNoSaleItems) {
        NotificationManager.warning(
          "Promo codes cannot be applied to items already on sale"
        );
      }
      if (promoCode === "") {
        setError("Promo code is required");
        return;
      }
      setLoading(true);
      const response = await applyCouponCode(promoCode, selectedCustomer?.id);
      if (response.status === 200) {
        const minimumApplicableAmount = response.data?.data?.min_amount;
        if (minimumApplicableAmount > findSubTotalAmount()) {
          const minUserCurrencyAmount = parseInt(minimumApplicableAmount);
          NotificationManager.warning(
            `Minimum cart amount ${minUserCurrencyAmount}  required for promo`
          );
          setLoading(false);
        } else {
          NotificationManager.success("Disocut applied");
          onApplyPromoCode(promoCode, response.data.data, findSubTotalAmount());
          setIsPromoCode(true);
          setLoading(false);
          onClose();
        }
      }
    } catch (error) {
      setLoading(false);

      console.log("error", error);
      requestErrorHandel({ error: error });
    }
  };

  const handleExit = () => {
    // Logic for closing the modal goes here
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h4>Enter Promo Code</h4>
            <input
              type="text"
              placeholder="Promo code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              onFocus={() => setError("")}
            />
            {error && <span className="error_message">{error}</span>}
            <br />
            <Row justify="end">
              <Button className="custom_buttons" onClick={handleExit}>
                Close
              </Button>
              <Button
                type="primary"
                className="custom_buttons"
                onClick={handleApply}
                disabled={isPromoCode}
              >
                {loading ? "Applying.." : isPromoCode ? "Applied" : "Apply"}
              </Button>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default PromoCode;
