// homeScreen
const endPoints = {
  // countries
  COUNTRIES_META_DATA: "/countries-metadata",

  // cart
  CART: "/carts",
  COUPON: "promo-codes",

  // customers
  CUSTOMERS: "/cms/users/search",
  ADDRESSES: "/cms/users/addresses",
  NEW_ADDRESS: "/cms/users/add-address",
  NEW_CUSTOMER: "/cms/users/create",

  // place order
  PLACE_ORDER: "/orders/order-refractor",
};
export default endPoints;
