import React, { useCallback, useEffect, useRef, useState } from "react";
import "./CustomerSearch.css"; // Make sure to create a CustomerSearch.css file in the same directory
import { Card, Form, Spin } from "antd";
import CustomerSection from "./CustomerSection";
import { searchCustomers } from "apis/cart-apis/cartApis";
import { searchCustomerAddresses } from "apis/cart-apis/cartApis";
import AddressesModal from "./address-modal/AddressesModal";
import { CheckCircleOutlined } from "@ant-design/icons";
import CreateNewCustomerModal from "./customer-modal/CreateNewCustomer";
import { createNewCustomer } from "apis/cart-apis/cartApis";
import { successMessage } from "utils/helpers/helpers";
import { requestErrorHandel } from "utils/helpers/helpers";
import { useDispatch, useSelector } from "react-redux";
import { _setCustomer } from "redux/actions/app/cart-actions/generalActions";
import { _setCustomerAddress } from "redux/actions/app/cart-actions/generalActions";

const CustomerSearch = () => {
  const customerForm = Form.useForm();
  const dispatch = useDispatch();

  const selectedCustomer = useSelector(
    (state) => state.general.selectedCustomer
  );
  const selectedCustomerAddress = useSelector(
    (state) => state.general.selectedCustomerAddress
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerModalOpen, setCustomerModalOpen] = useState(false);

  const [addresses, setAddresses] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [searchQuery, setSearchQuery] = useState("");
  const [phone, setPhone] = useState({
    phone_code: "",
    phone: "",
  });

  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const debounceTimeout = useRef(null);

  const handelSearchCustomer = useCallback(async () => {
    try {
      setLoading(true);
      const response = await searchCustomers(searchQuery);
      if (response.status === 200) {
        setCustomers(response.data?.data);
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      requestErrorHandel({ error: error });
      setLoading(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      if (searchQuery.trim() !== "") {
        handelSearchCustomer();
      } else {
        // Call the API when searchQuery is empty (cleared)
        handelSearchCustomer();
      }
    }, 1000); // Adjust the delay time as needed

    return () => {
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }
    }; // Cleanup the timeout
  }, [searchQuery, handelSearchCustomer]);

  const handleFocus = () => {
    setModalVisible(true);
  };

  const getCustomerAddresses = async (id) => {
    try {
      setLoading(true);
      const response = await searchCustomerAddresses(id);
      if (response.status === 200) {
        const _addresses = response.data.data;

        setAddresses(_addresses);
        if (_addresses.length > 0) {
          const latestAddress = _addresses[_addresses.length - 1];
          dispatch(_setCustomerAddress(latestAddress));
          setLoading(false);
        } else {
          dispatch(_setCustomerAddress({}));
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
      console.log("err0r", error);
    }
  };
  const handelSelectUser = async (item) => {
    dispatch(_setCustomer(item));
    await getCustomerAddresses(item.id);
  };

  const handleBlur = () => {
    // Delay hiding modal to allow for item selection
    setTimeout(() => setModalVisible(false), 200);
  };

  const handelSelectCustomerAddress = (address, index) => {
    const updatedAddresses = addresses.map((addr, idx) => {
      if (idx === index) {
        return { ...addr, isCheck: !addr.isCheck };
      } else {
        return { ...addr, isCheck: false }; // Deselect other addresses
      }
    });

    dispatch(_setCustomerAddress(address));
    setAddresses(updatedAddresses);
  };

  const onOpenAddressModal = async () => {
    setIsAddressModalOpen(true);
    if (addresses.length > 0) {
      return;
    } else {
      await getCustomerAddresses(selectedCustomer?.id);
    }
  };

  const onSave = useCallback(
    (phone, phoneCode) => {
      setPhone({
        phone_code: phoneCode,
        phone: phone,
      });
    },
    [setPhone]
  );

  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const payload = {
        ...values,
        phone: phone.phone,
        phone_code: phone.phone_code,
        username: values?.email.trim(),
      };

      const response = await createNewCustomer(payload);
      if (response.status === 200) {
        let _customer = [...customers];
        _customer.push(response.data?.data?.customer);
        dispatch(_setCustomer(response.data.data?.customer));
        successMessage({ message: "Customer has been created" });
        setLoading(false);
        setCustomerModalOpen(false);
      }
    } catch (error) {
      setLoading(false);
      requestErrorHandel({ error: error });
    }
  };

  return (
    <div className="customer-search-container">
      <h5>Customer</h5>
      <input
        id="customer-search"
        type="text"
        placeholder="Search or create a customer"
        onFocus={handleFocus}
        onChange={handleSearch}
        value={searchQuery}
        onBlur={handleBlur}
        autoComplete="off"
      />
      {isModalVisible && (
        <div className="customer-search-modal">
          <div
            className="create-new-customer"
            onClick={() => setCustomerModalOpen(true)}
          >
            <b>
              <span>+</span>
            </b>{" "}
            Add New Customer
          </div>

          {loading ? (
            <div className="spiner">
              <Spin />
            </div>
          ) : customers?.length === 0 ? (
            <div className="custom_empty_div">
              <p>No customers found</p>
            </div>
          ) : (
            <React.Fragment>
              {customers?.map((customer, index) => {
                const isCheck = selectedCustomer?.id === customer?.id;
                return (
                  <div
                    key={index}
                    className="customer-search-result"
                    onClick={() => handelSelectUser(customer)}
                  >
                    <div className="customer-search_result_container">
                      <div className="search_result_info">
                        <p className="custom_title">
                          {customer?.first_name + " " + customer?.last_name}
                        </p>
                        <span>{customer?.email}</span>
                      </div>

                      {isCheck && <CheckCircleOutlined />}
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div>
      )}
      <CustomerSection
        onShowAllAddresses={onOpenAddressModal}
        getCustomerAddresses={getCustomerAddresses}
      />
      <AddressesModal
        isOpen={isAddressModalOpen}
        addresses={addresses}
        onClick={handelSelectCustomerAddress}
        onClose={() => setIsAddressModalOpen(false)}
        loading={loading}
      />
      <CreateNewCustomerModal
        formName={customerForm}
        onSave={onSave}
        onFinish={onSubmit}
        isOpen={customerModalOpen}
        onClose={() => setCustomerModalOpen(false)}
        loading={loading}
      />
    </div>
  );
};

export default CustomerSearch;
