import { applyMiddleware, combineReducers, createStore } from "redux";
import persistReducer from "redux-persist/es/persistReducer";

import storage from "redux-persist/lib/storage";
import persistStore from "redux-persist/es/persistStore";
import thunk from "redux-thunk";
import authReducer from "./reducers/auth/authReducer";
import cartReducer from "./reducers/app/cart-reducer/cart";
import generalReducer from "./reducers/app/cart-reducer/generalReducer";

const rootReducer = combineReducers({
  auth: authReducer,
  cart: cartReducer,
  general: generalReducer
});
const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const Store = createStore(persistedReducer, applyMiddleware(thunk));
export const persistor = persistStore(Store);
