import React, { useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { Nav } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import { LOCAL_STORAGE_KEYS } from "utils/constants";
import logo from "../../assets/img/logo.svg";

const Sidebar = (props) => {
  const sidebar = useRef(null);

  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  useEffect(() => {
    let ps;
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebar.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }

    return () => {
      if (navigator.platform.indexOf("Win") > -1 && ps) {
        ps.destroy();
      }
    };
  }, []);

  const renderDashboard = (prop) => {
    return (
      <NavLink
        to={{
          pathname: `${prop.layout}${prop.path}`,
          state: prop.state,
        }}
        className="nav-link"
        activeClassName="active"
        exact
        onClick={() =>
          localStorage.removeItem(LOCAL_STORAGE_KEYS.DASHBOARD_TAB)
        }
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={prop.icon}
            style={{
              width: "20px",
              height: "20px",
              marginRight: "10px",
            }}
            alt="icon"
          />
          <p>{prop.name}</p>
        </div>
      </NavLink>
    );
  };

  return (
    <div
      className="sidebar"
      data-color={props.bgColor}
      data-active-color={props.activeColor}
    >
      <div className="logo d-flex justify-content-center align-items-center mt-2">
        <Link to={"/admin"}>
          <img
            src={logo}
            alt="app-logo"
            style={{ objectFit: "contain", height: 20 }}
          />
        </Link>
      </div>
      <div className="sidebar-wrapper" ref={sidebar}>
        <Nav>
          <>
            {props.routes.map((prop, key) => {
              return (
                <li
                  className={
                    activeRoute(prop.path) + (prop.pro ? " active-pro" : "")
                  }
                  key={key}
                >
                  {prop.path === "/dashboard" ? (
                    renderDashboard(prop)
                  ) : (
                    <NavLink
                      to={{
                        pathname: `${prop.layout}${prop.path}`,
                        state: prop.state,
                      }}
                      className="nav-link"
                      activeClassName="active"
                      exact
                    >
                      <div
                        style={{ display: "flex", alignItems: "center" }}
                        // onClick={() => {
                        //   if (prop.key === "new_order") {
                        //     localStorage.setItem("session_id", "");
                        //   }
                        // }}
                      >
                        <img
                          src={prop.icon}
                          style={{
                            width: "20px",
                            height: "20px",
                            marginRight: "10px",
                          }}
                          alt="icon"
                        />
                        <p>{prop.name}</p>
                      </div>
                    </NavLink>
                  )}
                </li>
              );
            })}
          </>
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
