import React from "react";
import { Container, Row } from "reactstrap";
import PropTypes from "prop-types";

const Footer = (props) => {
  return (
    <footer className={`footer${props.default ? " footer-default" : ""}`}>
      <Container fluid={props.fluid}>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <a href="/" target="_blank">
                  COGETER.COM
                </a>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <div className="copyright">
              &copy; {1900 + new Date().getYear()}, made for{" "}
              <a href="/">COGETER.COM</a>
            </div>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
