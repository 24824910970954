import React, { useEffect, useRef } from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Switch } from "react-router-dom";
import Footer from "components/Footer/Footer.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import { ClipLoader } from "react-spinners";
import AdminRoutes from "routes/AdminRoutes";
import HeaderNavBar from "components/Navbars/HeaderNavBar";
import { useDispatch } from "react-redux";
import { toNumber } from "utils/helpers/helpers";
import { getCountriesMetaData } from "apis/generalApis";
import { _getCountriesMetaData } from "redux/actions/app/cart-actions/generalActions";
import { isNonStringNullOrUndefined } from "utils/helpers/helpers";

let ps;

const Dashboard = (props) => {
  const dispatch = useDispatch();
  const backgroundColor = "white";
  const activeColor = "info";
  const loading = false;
  const mainPanel = useRef(null);

  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (userAgent.indexOf("win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle("perfect-scrollbar-on");

      return () => {
        ps.destroy();
        document.body.classList.toggle("perfect-scrollbar-on");
      };
    }
  }, []);

  useEffect(() => {
    const handleScrollTop = () => {
      mainPanel.current.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
    };

    window.addEventListener("popstate", handleScrollTop);
    return () => {
      window.removeEventListener("popstate", handleScrollTop);
    };
  }, []);

  useEffect(() => {
    fetchCountriesMetaData();
  }, []);

  const propertiesToConvert = [
    "pick_n_pack_fee",
    "cod_fee",
    "standard_delivery_fee",
    "same_day_delivery_fee",
    "government_export_tax_percentage",
    "fuel_surcharge_percentage",
    "free_shipping_threshold",
  ];

  const fetchCountriesMetaData = async () => {
    try {
      const response = await getCountriesMetaData();
      if (response.status === 200) {
        let formattedData = response.data?.data?.map((item) => {
          const _deliveryTaxes = item?.delivery_taxes;
          // Convert specific properties to numbers or replace null with 0
          propertiesToConvert.forEach((key) => {
            if (
              _deliveryTaxes.hasOwnProperty(key) &&
              (typeof _deliveryTaxes[key] === "string" ||
                _deliveryTaxes[key] === null)
            ) {
              _deliveryTaxes[key] = toNumber(_deliveryTaxes[key]);
            }
          });
          return { ...item, delivery_taxes: _deliveryTaxes };
        });

        dispatch(_getCountriesMetaData(formattedData));
      }
    } catch (error) {
      //errorRequestHandel({ error: error });
    }
  };

  return (
    <div>
      {loading ? (
        <div style={{ height: "50vh" }}>
          <ClipLoader size={50} color="#1A60A6" loading />
        </div>
      ) : (
        <div>
          <Sidebar
            {...props}
            routes={AdminRoutes}
            bgColor={backgroundColor}
            activeColor={activeColor}
          />
          <div
            className="main-panel d-flex flex-column justify-content-between h-100"
            ref={mainPanel}
          >
            <HeaderNavBar {...props} />
            <Switch>
              {AdminRoutes.map((prop, key) => {
                return prop.subMenu && prop.subMenu?.length > 0 ? (
                  prop.subMenu.map((subMenuProp, subMenuKey) => (
                    <Route
                      path={subMenuProp.layout + subMenuProp.path}
                      component={subMenuProp.component}
                      key={subMenuKey}
                      exact
                    />
                  ))
                ) : (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                    exact
                  />
                );
              })}
            </Switch>
            <Footer fluid />
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
