import React, { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { useSelector } from "react-redux";
import { setRules } from "utils/helpers/helpers";
const { Option } = Select;

const CreateNewCustomerModal = ({
  isOpen,
  onClose,
  formName,
  onFinish,
  loading,
  onSave,
}) => {
  const _countryList = useSelector((state) => state.general.countriesMetaData);
  const [selectedCountry, setSelectedCountry] = useState(_countryList[0]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const cleanedNumber = inputValue.replace(/\D/g, "");
    if (cleanedNumber !== inputValue) {
      event.target.value = cleanedNumber;
    }
    if (cleanedNumber.startsWith("0")) {
      setPhoneNumber("");
    } else {
      setPhoneNumber(cleanedNumber.replace(/^0+/, ""));
      setError("");
    }
  };

  useEffect(() => {
    onSave(phoneNumber, selectedCountry?.phone_code);
  }, [selectedCountry?.phone_code, phoneNumber, onSave]);

  const onFocusOut = () => {
    if (phoneNumber.length < selectedCountry?.phone_no_limit) {
      setError(
        `Phone number should be ${selectedCountry?.phone_no_limit} digits`
      );
    } else {
      setError("");
    }
  };

  const renderAddNewCustomerForm = () => {
    return (
      <div>
        <br />
        <Form layout="vertical" name={formName} onFinish={onFinish}>
          <Row gutter={10}>
            <Col md={12}>
              <Form.Item name={"first_name"} rules={setRules("First Name")}>
                <Input type="text" placeholder="First Name" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name={"last_name"} rules={setRules("Last Name")}>
                <Input type="text" placeholder="Last Name" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col md={12}>
              <Form.Item name={"email"} rules={setRules("Email")}>
                <Input type="email" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col md={12}>
              <Form.Item name={"password"} rules={setRules("Password")}>
                <Input type="password" placeholder="Password" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={1}>
            <Col md={6}>
              <Form.Item>
                <Select
                  placeholder=""
                  onChange={(value) => {
                    const selectedCountry = _countryList.find(
                      (country) => country.phone_code === value
                    );
                    setSelectedCountry(selectedCountry);
                    setPhoneNumber("");
                  }}
                  value={selectedCountry?.phone_code}
                >
                  {_countryList.map((country) => (
                    <Option value={country.phone_code} key={country.id}>
                      <div className="country_picker_code">
                        <img src={country?.icon} alt="country_flag" />
                        {country.phone_code}
                      </div>
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col md={18}>
              <Form.Item>
                <Input
                  type="tel"
                  onChange={(e) => {
                    handlePhoneNumberChange(e);
                  }}
                  value={phoneNumber}
                  maxLength={selectedCountry.phone_no_limit}
                  onBlur={onFocusOut}
                  required
                />
                <span className="error">{error ?? ""}</span>
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Row justify="end">
            <Button
              className="custom_buttons"
              onClick={onClose}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className="custom_buttons"
              type="primary"
              // onClick={handleExit}
            >
              {loading ? "Save...." : "Save"}
            </Button>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="addresses-modal-content">
            <h4>Create New Customer</h4>

            {renderAddNewCustomerForm()}
          </div>
        </div>
      )}
    </>
  );
};

export default CreateNewCustomerModal;
