import React from "react";
import "./addressesModal.css"; // Make sure to create a CustomModal.css file for styling
import { Button, Checkbox, Row, Spin } from "antd";
import { useSelector } from "react-redux";

const AddressesModal = ({ isOpen, onClose, addresses, onClick, loading }) => {
  const selectedCustomerAddress = useSelector(
    (state) => state.general.selectedCustomerAddress
  );
  const handleExit = () => {
    // Logic for closing the modal goes here
    onClose();
  };

  const renderCustomerAddress = () => {
    return (
      <div className="addressess_container_section">
        {addresses?.length > 0 ? (
          <React.Fragment>
            {addresses?.map((address, index) => {
              const isChecked = selectedCustomerAddress.id === address.id;
              return (
                <div
                  key={index}
                  className="address_container"
                  onClick={() => onClick(address, index)}
                >
                  <div className="address_detail">
                    <span className="custom_title">
                      {address.address_line_1}
                    </span>
                    <p>{address.address_line_2}</p>
                    <p>{address.city}</p>
                    <p>{address.state}</p>
                    <p>{address.country?.name}</p>
                  </div>
                  <div className="address_detail_checked">
                    <Checkbox checked={isChecked} />
                  </div>
                </div>
              );
            })}
          </React.Fragment>
        ) : (
          <div className="custom_empty_container">
            <p className="custom_title">Addresses not found</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {isOpen && (
        <div className="modal-overlay">
          <div className="addresses-modal-content">
            <h4>Customer Addresses</h4>

            {loading ? (
              <div className="spiner">
                <Spin />
              </div>
            ) : (
              renderCustomerAddress()
            )}

            <br />
            <Row justify="end">
              <Button
                className="custom_buttons"
                type="primary"
                onClick={handleExit}
              >
                Done
              </Button>
            </Row>
          </div>
        </div>
      )}
    </>
  );
};

export default AddressesModal;
