import {
  ADD_ITEM_TO_CART,
  CART_ITEMS_FAILURE,
  CART_ITEMS_SUCCESS,
  CART_START,
  CART_STOP,
  CLEAR_CART,
  DECREMENT_ITEM_QUNATITY,
  GET_ALL_CART_ITEMS,
  INCREAMENT_ITEM_QUNATITY,
  REMOVE_ITEM_FROM_CART,
} from "redux/actions/app/cart-actions/cart";

const initialState = {
  cartItems: [],
  _isLoading: false,
  _loading: false,
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_CART_ITEMS:
      return {
        ...state,
        cartItems: action.payload,
      };
    case ADD_ITEM_TO_CART:
      const { product, variant } = action.payload;
      const cartItem = {
        productId: product.id,
        identifier: product?.identifier,
        title: product.title,
        regularPrice: product.regular_price,
        onSale: product.on_sale,
        salePrice: product.sale_price,
        coverImage: product.cover_image,
        variantId: variant.id,
        variantSize: variant.size,
        quantity: 1,
      };

      const existingCartItem = state.cartItems?.find(
        (item) => item.variantId === cartItem.variantId
      );
      if (existingCartItem) {
        return {
          ...state,
          cartItems: state?.cartItems?.map((item) =>
            item.variantId === existingCartItem.variantId
              ? { ...item, quantity: parseInt(item.quantity) + 1 }
              : item
          ),
        };
      }

      return {
        ...state,
        cartItems: [...state?.cartItems, cartItem],
      };
      break;
    case INCREAMENT_ITEM_QUNATITY:
      return {
        ...state,
        cartItems: state.cartItems?.map((item) =>
          item.variantId === action.payload
            ? { ...item, quantity: parseInt(item.quantity) + 1 }
            : item
        ),
      };
      break;
    case DECREMENT_ITEM_QUNATITY:
      return {
        ...state,
        cartItems: state.cartItems?.map((item) =>
          item.variantId === action.payload && item.quantity > 1
            ? { ...item, quantity: parseInt(item.quantity) - 1 }
            : item
        ),
      };
      break;
    case REMOVE_ITEM_FROM_CART:
      const updatedCartItems = state.cartItems?.filter(
        (item) => item.variantId !== action.payload
      );
      return {
        ...state,
        cartItems: updatedCartItems,
      };
      break;
    case CLEAR_CART:
      return {
        ...state,
        cartItems: [],
      };
      break;
    case CART_START:
      return {
        ...state,
        _isLoading: true,
      };
      break;
    case CART_STOP:
      return {
        ...state,
        _isLoading: false,
      };
      break;
    case CART_ITEMS_SUCCESS:
      return {
        ...state,
        _loading: true,
      };
      break;
    case CART_ITEMS_FAILURE:
      return {
        ...state,
        _loading: false,
      };
      break;

    default:
      return state;
  }
}
